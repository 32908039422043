export const dhStudy = {
  study: 'Study | Studies',
  participant: {
    this: 'Participant | Participants',
    participant_id: 'PID',
    state: 'State | States',
    active: 'Active',
    inactive: 'Inactive',
    lastData: 'Last Data',
    copyPID: 'Copy PID',
  },
  deviceAssignment: {
    this: 'Device Assignment | Device Assignments',
    device_name: 'Device Name',
    device_id: 'Device ID',
    start_time: 'Start Time',
    end_time: 'End Time',
    swapDevice: 'Swap Device',
    confirmDelete: 'Do you really want to delete this device assignment?',
    confirmEndNow: 'Do you really want to end this device assignment now?',
  },
  device: {
    this: 'Device | Devices',
    device_name: 'Device Name',
    device_id: 'Device ID',
    model: 'Model',
    assigned_pid: 'Assigned PID',
    lastData: 'Last Data',
    battery: 'Battery',
    copyUUID: 'Copy device UUID',
  },
  sourceDevice: {
    this: 'Source Device | Source Devices',
  },
  availableDevice: {
    this: 'Available Device | Available Devices',
  },
  endNow: 'End Now',
  data: 'Data',
};
