import { apiClient } from '@/api/ApiClient';
import { apiClientV2 } from '@/api/ApiClientV2';
import { ApiClientPlugin } from '@/api/ApiClientPlugin';
import { ApiClientPluginV2 } from '@/api/ApiClientPluginV2';
import App from './App.vue';
import { BackgroundTaskHandler } from '@/api/BackgroundTaskHandler';
import { ErrorHandlerClass } from '@/api/ErrorHandler';
import Buefy from 'buefy';

import {
  CHANNEL_DEFAULT,
  DELIVERY_METHOD_DEFAULT,
  DELIVERY_PROCEDURE_DEFAULT,
  FIRMWARE_DEFAULT,
} from '@/models/firmware/defaults';
import ErrorPage from './ErrorPage.vue';
import router from '@/router/index';
import store from '@/store/index';
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import { createPinia, PiniaPlugin } from 'pinia';
import Router from 'vue-router';
import { i18n, translate } from '@/lang/setup';
import VueRx from 'vue-rx';
import { RouterHandler } from './router/routerHandler';
import { initForms } from '@/components/common/forms/forms';
import BaseListV2 from '@/components/common/BaseListV2.vue';
import BaseFormV2 from '@/components/common/forms/BaseFormV2.vue';
import { DMS_FRONTEND_THEME, NODE_ENV, THEME } from './env';
import { globalStore } from './store/modules/global';
import { authStore } from './store/modules/auth/auth';
import { toLocaleDateString, toLocaleString } from './devicehub/utils/filters';

// apply theme from env variable
let defaultTheme = true;

// add custom theme here if applicable

// if (DMS_FRONTEND_THEME === THEME.XXX) {
//   // @ts-ignore
//   import('./apps/xxx/common/xxxTheme.scss')
//   defaultTheme = false
// }

if (DMS_FRONTEND_THEME === THEME.MARNE) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('./styles/marneTheme.scss');
  defaultTheme = false;
}

if (defaultTheme) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('./styles/defaultTheme.scss');
}

// Setup ApiClient
const defaultModelsMap = {
  'channel': CHANNEL_DEFAULT,
  'firmware': FIRMWARE_DEFAULT,
  'delivery-method': DELIVERY_METHOD_DEFAULT,
  'delivery-procedure': DELIVERY_PROCEDURE_DEFAULT,
};
const options = {
  defaultModelsMap,
};

Vue.use(new ApiClientPlugin(), options);
Vue.use(new ApiClientPluginV2(), options);
// need to use apiClient here to make sure store is available
apiClient.initialize();

Vue.use(Router);
Vue.use(RouterHandler);
Vue.use(BackgroundTaskHandler);
Vue.use(ErrorHandlerClass);

Vue.use(Buefy, {
  defaultDialogCancelText: translate('common.cancel'),
  defaultFirstDayOfWeek: 1, // Monday,
});
Vue.use(VueRx);
Vue.use(VueCompositionAPI);
Vue.use(PiniaPlugin);
const pinia = createPinia();

// global components
Vue.component('BaseListV2', BaseListV2);
Vue.component('BaseFormV2', BaseFormV2);

initForms();

// filters
Vue.filter('toLocaleString', toLocaleString);
Vue.filter('toLocaleDateString', toLocaleDateString);

function initDmsApplication(): Vue {
  apiClientV2.setAxiosConfig(authStore.axiosConfig);

  let vm;
  if (NODE_ENV === 'test') {
    vm = new Vue({
      router,
      store,
      pinia,
      i18n,
      render: h => h(App),
    });
  } else {
    vm = new Vue({
      router,
      store,
      pinia,
      i18n,
      render: h => h(App),
    }).$mount('#app');
  }

  // Init the RouterHandler with the Vue instance
  vm.$routerHandler.init(vm);
  window['vm'] = vm;
  return vm;
}

globalStore
  .initialize()
  .then(() => {
    return authStore.initialize();
  })
  .then(() => {
    initDmsApplication();
  })
  .catch(() => {
    // If we land here, the server is not responding. It's either offline or rejecting our request
    // for some reason (e.g. requires VPN connection). Simply show an error page.
    new Vue({
      render: h => h(ErrorPage),
    }).$mount('#app');
  });
