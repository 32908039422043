import { AssignmentDetail, DeviceDetail } from '@/generated/api/study';
import { defineStore } from 'pinia';
import { handleError } from '@/devicehub/utils/error';
import { useStudyApi } from './studyApi';

export interface DeviceAssignmentState {
  loading: boolean;
  deviceAssignmentsOfParticipant: AssignmentDetail[];
  availableDevices: DeviceDetail[];
  currentParticipantId: string | null;
  currentDeviceAssignment: AssignmentDetail | null;
  searchTerm: string;
}

export const useDeviceAssignmentStore = defineStore({
  id: 'deviceAssignment',
  state: (): DeviceAssignmentState => ({
    loading: false,
    deviceAssignmentsOfParticipant: [],
    availableDevices: [],
    currentParticipantId: null,
    currentDeviceAssignment: null,
    searchTerm: '',
  }),
  getters: {
    filteredAvailableDevices(state): DeviceDetail[] {
      return state.availableDevices.filter(d => {
        return JSON.stringify(d)
          .toLowerCase()
          .includes(state.searchTerm.trim().toLowerCase());
      });
    },
  },
  actions: {
    async getDeviceAssignmentsOfParticipant(
      participantId?: string,
    ): Promise<void> {
      const studyApi = useStudyApi();
      const pid = participantId ?? this.currentParticipantId;
      if (!pid) {
        return;
      }
      if (pid !== this.currentParticipantId) {
        this.deviceAssignmentsOfParticipant = [];
      }
      this.currentParticipantId = pid;
      this.loading = true;
      try {
        const response =
          await studyApi.assignmentApi.getAssignmentsDetailAssignmentsDetailGet(
            pid,
          );
        this.deviceAssignmentsOfParticipant = response.data;
      } catch (error) {
        this.deviceAssignmentsOfParticipant = [];
        handleError(error);
      }
      this.loading = false;
    },

    async getAvailableDevices(
      start: string,
      end?: string,
      excludeAssignmentId?: string,
    ): Promise<void> {
      this.loading = true;
      try {
        const studyApi = useStudyApi();
        const response =
          await studyApi.assignmentApi.getAvailableDevicesForAssignmentAssignmentsAvailableDevicesGet(
            start,
            end,
            excludeAssignmentId,
          );
        this.availableDevices = response.data;
      } catch (error) {
        this.availableDevices = [];
        handleError(error);
      }
      this.loading = false;
    },

    async createDeviceAssignment(
      deviceUuid: string,
      participantId: string,
      start: string,
      end?: string,
    ): Promise<void> {
      this.loading = true;
      try {
        const studyApi = useStudyApi();
        await studyApi.assignmentApi.createAssignmentAssignmentsPost(
          deviceUuid,
          participantId,
          start,
          end,
        );
      } catch (error) {
        handleError(error);
      }
      this.loading = false;
    },

    async updateDevice(
      assignmentDetail: AssignmentDetail,
      deviceUuid: string,
    ): Promise<void> {
      this.loading = true;
      try {
        const studyApi = useStudyApi();
        if (!assignmentDetail.assignment.id) {
          throw new Error('No assignment selected');
        }
        await studyApi.assignmentApi.updateAssignmentDeviceAssignmentsUpdateDevicePut(
          assignmentDetail.assignment.id,
          deviceUuid,
        );
      } catch (error) {
        handleError(error);
      }
      this.loading = false;
    },

    async updateStart(
      assignmentDetail: AssignmentDetail,
      start: string,
    ): Promise<void> {
      this.loading = true;
      try {
        const studyApi = useStudyApi();
        if (!assignmentDetail.assignment.id) {
          throw new Error('No assignment selected');
        }
        await studyApi.assignmentApi.updateAssignmentStartAssignmentsUpdateStartPut(
          assignmentDetail.assignment.id,
          start,
        );
      } catch (error) {
        handleError(error);
      }
      this.loading = false;
    },

    async updateEnd(
      assignmentDetail: AssignmentDetail,
      end: string,
    ): Promise<void> {
      this.loading = true;
      try {
        const studyApi = useStudyApi();
        if (!assignmentDetail.assignment.id) {
          throw new Error('No assignment selected');
        }
        await studyApi.assignmentApi.updateAssignmentEndAssignmentsUpdateEndPut(
          assignmentDetail.assignment.id,
          end,
        );
      } catch (error) {
        handleError(error);
      }
      this.loading = false;
    },

    async deleteDeviceAssignment(
      assignmentDetail: AssignmentDetail,
    ): Promise<void> {
      this.loading = true;
      try {
        const studyApi = useStudyApi();
        if (!assignmentDetail.assignment.id) {
          throw new Error('No assignment selected');
        }
        await studyApi.assignmentApi.deleteAssignmentAssignmentsDeletePut(
          assignmentDetail.assignment.id,
        );
      } catch (error) {
        handleError(error);
      }
      this.loading = false;
    },

    async endDeviceAssignmentNow(
      assignmentDetail: AssignmentDetail,
    ): Promise<void> {
      this.loading = true;
      try {
        const studyApi = useStudyApi();
        if (!assignmentDetail.assignment.id) {
          throw new Error('No assignment selected');
        }
        await studyApi.assignmentApi.endAssignmentNowAssignmentsEndNowPut(
          assignmentDetail.assignment.id,
        );
      } catch (error) {
        handleError(error);
      }
      this.loading = false;
    },
  },
});
