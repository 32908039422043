/* tslint:disable */
/* eslint-disable */
/**
 * DeviceHub Study App
 * DeviceHub Study App Package
 *
 * The version of the OpenAPI document: 0.0.0-dev
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Assignment
 */
export interface Assignment {
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    id?: string;
    /**
     * 
     * @type {BucketRef}
     * @memberof Assignment
     */
    from_ref: BucketRef;
    /**
     * 
     * @type {BucketRef}
     * @memberof Assignment
     */
    to_ref: BucketRef;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    end?: string;
}
/**
 * 
 * @export
 * @interface AssignmentDetail
 */
export interface AssignmentDetail {
    /**
     * 
     * @type {Assignment}
     * @memberof AssignmentDetail
     */
    assignment: Assignment;
    /**
     * 
     * @type {Device}
     * @memberof AssignmentDetail
     */
    device: Device;
}
/**
 * 
 * @export
 * @interface Assignments
 */
export interface Assignments {
    /**
     * 
     * @type {Array<Assignment>}
     * @memberof Assignments
     */
    assignments: Array<Assignment>;
}
/**
 * 
 * @export
 * @interface BucketRef
 */
export interface BucketRef {
    /**
     * 
     * @type {string}
     * @memberof BucketRef
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BucketRef
     */
    type: string;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    create_time: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    creator?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    device_id: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    classification?: string;
}
/**
 * 
 * @export
 * @interface DeviceDetail
 */
export interface DeviceDetail {
    /**
     * 
     * @type {Device}
     * @memberof DeviceDetail
     */
    device: Device;
    /**
     * 
     * @type {string}
     * @memberof DeviceDetail
     */
    device_last_timestamp?: string;
    /**
     * 
     * @type {object}
     * @memberof DeviceDetail
     */
    device_state?: object;
    /**
     * 
     * @type {string}
     * @memberof DeviceDetail
     */
    device_active_assigned_pid?: string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    detail?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface InfoDto
 */
export interface InfoDto {
    /**
     * 
     * @type {string}
     * @memberof InfoDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InfoDto
     */
    version: string;
}
/**
 * 
 * @export
 * @interface Participant
 */
export interface Participant {
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    participant_id: string;
    /**
     * 
     * @type {string}
     * @memberof Participant
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ParticipantDetail
 */
export interface ParticipantDetail {
    /**
     * 
     * @type {string}
     * @memberof ParticipantDetail
     */
    participant_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDetail
     */
    is_active: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDetail
     */
    last_timestamp?: string;
}
/**
 * 
 * @export
 * @interface Participants
 */
export interface Participants {
    /**
     * 
     * @type {Array<Participant>}
     * @memberof Participants
     */
    participants: Array<Participant>;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    loc: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    msg: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    type: string;
}

/**
 * AssignmentApi - axios parameter creator
 * @export
 */
export const AssignmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Assignment
         * @param {string} deviceUuid Device id from excel file on SharePoint
         * @param {string} participantId The participant id that was provided when creating the participant
         * @param {string} start Date and time when the assignment is starting.
         * @param {string} [end] Optional field to already set an end date to the assignment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignmentAssignmentsPost: async (deviceUuid: string, participantId: string, start: string, end?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('createAssignmentAssignmentsPost', 'deviceUuid', deviceUuid)
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('createAssignmentAssignmentsPost', 'participantId', participantId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('createAssignmentAssignmentsPost', 'start', start)
            const localVarPath = `/assignments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceUuid !== undefined) {
                localVarQueryParameter['device_uuid'] = deviceUuid;
            }

            if (participantId !== undefined) {
                localVarQueryParameter['participant_id'] = participantId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Assignment
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignmentAssignmentsDeletePut: async (assignmentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('deleteAssignmentAssignmentsDeletePut', 'assignmentId', assignmentId)
            const localVarPath = `/assignments/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assignmentId !== undefined) {
                localVarQueryParameter['assignment_id'] = assignmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End Assignment Now
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endAssignmentNowAssignmentsEndNowPut: async (assignmentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('endAssignmentNowAssignmentsEndNowPut', 'assignmentId', assignmentId)
            const localVarPath = `/assignments/end-now`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assignmentId !== undefined) {
                localVarQueryParameter['assignment_id'] = assignmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Assignments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentsAssignmentsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/assignments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Assignments Detail
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentsDetailAssignmentsDetailGet: async (participantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('getAssignmentsDetailAssignmentsDetailGet', 'participantId', participantId)
            const localVarPath = `/assignments/detail/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (participantId !== undefined) {
                localVarQueryParameter['participant_id'] = participantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Available Devices For Assignment
         * @param {string} start 
         * @param {string} [end] 
         * @param {string} [excludeAssignmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDevicesForAssignmentAssignmentsAvailableDevicesGet: async (start: string, end?: string, excludeAssignmentId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getAvailableDevicesForAssignmentAssignmentsAvailableDevicesGet', 'start', start)
            const localVarPath = `/assignments/available-devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (excludeAssignmentId !== undefined) {
                localVarQueryParameter['exclude_assignment_id'] = excludeAssignmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Assignment Device
         * @param {string} assignmentId 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentDeviceAssignmentsUpdateDevicePut: async (assignmentId: string, deviceUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('updateAssignmentDeviceAssignmentsUpdateDevicePut', 'assignmentId', assignmentId)
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('updateAssignmentDeviceAssignmentsUpdateDevicePut', 'deviceUuid', deviceUuid)
            const localVarPath = `/assignments/update-device`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assignmentId !== undefined) {
                localVarQueryParameter['assignment_id'] = assignmentId;
            }

            if (deviceUuid !== undefined) {
                localVarQueryParameter['device_uuid'] = deviceUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Assignment End
         * @param {string} assignmentId 
         * @param {string} end Date and time when the assignment is ending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentEndAssignmentsUpdateEndPut: async (assignmentId: string, end: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('updateAssignmentEndAssignmentsUpdateEndPut', 'assignmentId', assignmentId)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('updateAssignmentEndAssignmentsUpdateEndPut', 'end', end)
            const localVarPath = `/assignments/update-end`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assignmentId !== undefined) {
                localVarQueryParameter['assignment_id'] = assignmentId;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Assignment Start
         * @param {string} assignmentId 
         * @param {string} start Date and time when the assignment starts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentStartAssignmentsUpdateStartPut: async (assignmentId: string, start: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('updateAssignmentStartAssignmentsUpdateStartPut', 'assignmentId', assignmentId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('updateAssignmentStartAssignmentsUpdateStartPut', 'start', start)
            const localVarPath = `/assignments/update-start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assignmentId !== undefined) {
                localVarQueryParameter['assignment_id'] = assignmentId;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssignmentApi - functional programming interface
 * @export
 */
export const AssignmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssignmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Assignment
         * @param {string} deviceUuid Device id from excel file on SharePoint
         * @param {string} participantId The participant id that was provided when creating the participant
         * @param {string} start Date and time when the assignment is starting.
         * @param {string} [end] Optional field to already set an end date to the assignment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssignmentAssignmentsPost(deviceUuid: string, participantId: string, start: string, end?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssignmentAssignmentsPost(deviceUuid, participantId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Assignment
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssignmentAssignmentsDeletePut(assignmentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssignmentAssignmentsDeletePut(assignmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary End Assignment Now
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endAssignmentNowAssignmentsEndNowPut(assignmentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endAssignmentNowAssignmentsEndNowPut(assignmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Assignments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignmentsAssignmentsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignments>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentsAssignmentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Assignments Detail
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignmentsDetailAssignmentsDetailGet(participantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssignmentDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentsDetailAssignmentsDetailGet(participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Available Devices For Assignment
         * @param {string} start 
         * @param {string} [end] 
         * @param {string} [excludeAssignmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableDevicesForAssignmentAssignmentsAvailableDevicesGet(start: string, end?: string, excludeAssignmentId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableDevicesForAssignmentAssignmentsAvailableDevicesGet(start, end, excludeAssignmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Assignment Device
         * @param {string} assignmentId 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssignmentDeviceAssignmentsUpdateDevicePut(assignmentId: string, deviceUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignmentDeviceAssignmentsUpdateDevicePut(assignmentId, deviceUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Assignment End
         * @param {string} assignmentId 
         * @param {string} end Date and time when the assignment is ending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssignmentEndAssignmentsUpdateEndPut(assignmentId: string, end: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignmentEndAssignmentsUpdateEndPut(assignmentId, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Assignment Start
         * @param {string} assignmentId 
         * @param {string} start Date and time when the assignment starts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssignmentStartAssignmentsUpdateStartPut(assignmentId: string, start: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignmentStartAssignmentsUpdateStartPut(assignmentId, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssignmentApi - factory interface
 * @export
 */
export const AssignmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssignmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Assignment
         * @param {string} deviceUuid Device id from excel file on SharePoint
         * @param {string} participantId The participant id that was provided when creating the participant
         * @param {string} start Date and time when the assignment is starting.
         * @param {string} [end] Optional field to already set an end date to the assignment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssignmentAssignmentsPost(deviceUuid: string, participantId: string, start: string, end?: string, options?: any): AxiosPromise<Assignment> {
            return localVarFp.createAssignmentAssignmentsPost(deviceUuid, participantId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Assignment
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignmentAssignmentsDeletePut(assignmentId: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteAssignmentAssignmentsDeletePut(assignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End Assignment Now
         * @param {string} assignmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endAssignmentNowAssignmentsEndNowPut(assignmentId: string, options?: any): AxiosPromise<any> {
            return localVarFp.endAssignmentNowAssignmentsEndNowPut(assignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Assignments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentsAssignmentsGet(options?: any): AxiosPromise<Assignments> {
            return localVarFp.getAssignmentsAssignmentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Assignments Detail
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignmentsDetailAssignmentsDetailGet(participantId: string, options?: any): AxiosPromise<Array<AssignmentDetail>> {
            return localVarFp.getAssignmentsDetailAssignmentsDetailGet(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Available Devices For Assignment
         * @param {string} start 
         * @param {string} [end] 
         * @param {string} [excludeAssignmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDevicesForAssignmentAssignmentsAvailableDevicesGet(start: string, end?: string, excludeAssignmentId?: string, options?: any): AxiosPromise<Array<DeviceDetail>> {
            return localVarFp.getAvailableDevicesForAssignmentAssignmentsAvailableDevicesGet(start, end, excludeAssignmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Assignment Device
         * @param {string} assignmentId 
         * @param {string} deviceUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentDeviceAssignmentsUpdateDevicePut(assignmentId: string, deviceUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.updateAssignmentDeviceAssignmentsUpdateDevicePut(assignmentId, deviceUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Assignment End
         * @param {string} assignmentId 
         * @param {string} end Date and time when the assignment is ending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentEndAssignmentsUpdateEndPut(assignmentId: string, end: string, options?: any): AxiosPromise<any> {
            return localVarFp.updateAssignmentEndAssignmentsUpdateEndPut(assignmentId, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Assignment Start
         * @param {string} assignmentId 
         * @param {string} start Date and time when the assignment starts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssignmentStartAssignmentsUpdateStartPut(assignmentId: string, start: string, options?: any): AxiosPromise<any> {
            return localVarFp.updateAssignmentStartAssignmentsUpdateStartPut(assignmentId, start, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssignmentApi - interface
 * @export
 * @interface AssignmentApi
 */
export interface AssignmentApiInterface {
    /**
     * 
     * @summary Create Assignment
     * @param {string} deviceUuid Device id from excel file on SharePoint
     * @param {string} participantId The participant id that was provided when creating the participant
     * @param {string} start Date and time when the assignment is starting.
     * @param {string} [end] Optional field to already set an end date to the assignment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    createAssignmentAssignmentsPost(deviceUuid: string, participantId: string, start: string, end?: string, options?: any): AxiosPromise<Assignment>;

    /**
     * 
     * @summary Delete Assignment
     * @param {string} assignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    deleteAssignmentAssignmentsDeletePut(assignmentId: string, options?: any): AxiosPromise<any>;

    /**
     * 
     * @summary End Assignment Now
     * @param {string} assignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    endAssignmentNowAssignmentsEndNowPut(assignmentId: string, options?: any): AxiosPromise<any>;

    /**
     * 
     * @summary Get Assignments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    getAssignmentsAssignmentsGet(options?: any): AxiosPromise<Assignments>;

    /**
     * 
     * @summary Get Assignments Detail
     * @param {string} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    getAssignmentsDetailAssignmentsDetailGet(participantId: string, options?: any): AxiosPromise<Array<AssignmentDetail>>;

    /**
     * 
     * @summary Get Available Devices For Assignment
     * @param {string} start 
     * @param {string} [end] 
     * @param {string} [excludeAssignmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    getAvailableDevicesForAssignmentAssignmentsAvailableDevicesGet(start: string, end?: string, excludeAssignmentId?: string, options?: any): AxiosPromise<Array<DeviceDetail>>;

    /**
     * 
     * @summary Update Assignment Device
     * @param {string} assignmentId 
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    updateAssignmentDeviceAssignmentsUpdateDevicePut(assignmentId: string, deviceUuid: string, options?: any): AxiosPromise<any>;

    /**
     * 
     * @summary Update Assignment End
     * @param {string} assignmentId 
     * @param {string} end Date and time when the assignment is ending.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    updateAssignmentEndAssignmentsUpdateEndPut(assignmentId: string, end: string, options?: any): AxiosPromise<any>;

    /**
     * 
     * @summary Update Assignment Start
     * @param {string} assignmentId 
     * @param {string} start Date and time when the assignment starts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApiInterface
     */
    updateAssignmentStartAssignmentsUpdateStartPut(assignmentId: string, start: string, options?: any): AxiosPromise<any>;

}

/**
 * AssignmentApi - object-oriented interface
 * @export
 * @class AssignmentApi
 * @extends {BaseAPI}
 */
export class AssignmentApi extends BaseAPI implements AssignmentApiInterface {
    /**
     * 
     * @summary Create Assignment
     * @param {string} deviceUuid Device id from excel file on SharePoint
     * @param {string} participantId The participant id that was provided when creating the participant
     * @param {string} start Date and time when the assignment is starting.
     * @param {string} [end] Optional field to already set an end date to the assignment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public createAssignmentAssignmentsPost(deviceUuid: string, participantId: string, start: string, end?: string, options?: any) {
        return AssignmentApiFp(this.configuration).createAssignmentAssignmentsPost(deviceUuid, participantId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Assignment
     * @param {string} assignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public deleteAssignmentAssignmentsDeletePut(assignmentId: string, options?: any) {
        return AssignmentApiFp(this.configuration).deleteAssignmentAssignmentsDeletePut(assignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary End Assignment Now
     * @param {string} assignmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public endAssignmentNowAssignmentsEndNowPut(assignmentId: string, options?: any) {
        return AssignmentApiFp(this.configuration).endAssignmentNowAssignmentsEndNowPut(assignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Assignments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public getAssignmentsAssignmentsGet(options?: any) {
        return AssignmentApiFp(this.configuration).getAssignmentsAssignmentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Assignments Detail
     * @param {string} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public getAssignmentsDetailAssignmentsDetailGet(participantId: string, options?: any) {
        return AssignmentApiFp(this.configuration).getAssignmentsDetailAssignmentsDetailGet(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Available Devices For Assignment
     * @param {string} start 
     * @param {string} [end] 
     * @param {string} [excludeAssignmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public getAvailableDevicesForAssignmentAssignmentsAvailableDevicesGet(start: string, end?: string, excludeAssignmentId?: string, options?: any) {
        return AssignmentApiFp(this.configuration).getAvailableDevicesForAssignmentAssignmentsAvailableDevicesGet(start, end, excludeAssignmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Assignment Device
     * @param {string} assignmentId 
     * @param {string} deviceUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public updateAssignmentDeviceAssignmentsUpdateDevicePut(assignmentId: string, deviceUuid: string, options?: any) {
        return AssignmentApiFp(this.configuration).updateAssignmentDeviceAssignmentsUpdateDevicePut(assignmentId, deviceUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Assignment End
     * @param {string} assignmentId 
     * @param {string} end Date and time when the assignment is ending.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public updateAssignmentEndAssignmentsUpdateEndPut(assignmentId: string, end: string, options?: any) {
        return AssignmentApiFp(this.configuration).updateAssignmentEndAssignmentsUpdateEndPut(assignmentId, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Assignment Start
     * @param {string} assignmentId 
     * @param {string} start Date and time when the assignment starts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public updateAssignmentStartAssignmentsUpdateStartPut(assignmentId: string, start: string, options?: any) {
        return AssignmentApiFp(this.configuration).updateAssignmentStartAssignmentsUpdateStartPut(assignmentId, start, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoInfoGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Prometheus Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prometheusRouteMetricsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoInfoGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Prometheus Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prometheusRouteMetricsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prometheusRouteMetricsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoInfoGet(options?: any): AxiosPromise<InfoDto> {
            return localVarFp.infoInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Prometheus Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prometheusRouteMetricsGet(options?: any): AxiosPromise<any> {
            return localVarFp.prometheusRouteMetricsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    infoInfoGet(options?: any): AxiosPromise<InfoDto>;

    /**
     * 
     * @summary Prometheus Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    prometheusRouteMetricsGet(options?: any): AxiosPromise<any>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @summary Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public infoInfoGet(options?: any) {
        return DefaultApiFp(this.configuration).infoInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Prometheus Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public prometheusRouteMetricsGet(options?: any) {
        return DefaultApiFp(this.configuration).prometheusRouteMetricsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DevicesApi - axios parameter creator
 * @export
 */
export const DevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Device Detail List
         * @param {string} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceDetailListDevicesDetailGet: async (model?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Devices
         * @param {string} [model] 
         * @param {string} [deviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesDevicesGet: async (model?: string, deviceId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['device_id'] = deviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevicesApi - functional programming interface
 * @export
 */
export const DevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Device Detail List
         * @param {string} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceDetailListDevicesDetailGet(model?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceDetailListDevicesDetailGet(model, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Devices
         * @param {string} [model] 
         * @param {string} [deviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevicesDevicesGet(model?: string, deviceId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Device>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicesDevicesGet(model, deviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevicesApi - factory interface
 * @export
 */
export const DevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Device Detail List
         * @param {string} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceDetailListDevicesDetailGet(model?: string, options?: any): AxiosPromise<Array<DeviceDetail>> {
            return localVarFp.getDeviceDetailListDevicesDetailGet(model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Devices
         * @param {string} [model] 
         * @param {string} [deviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesDevicesGet(model?: string, deviceId?: string, options?: any): AxiosPromise<Array<Device>> {
            return localVarFp.getDevicesDevicesGet(model, deviceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevicesApi - interface
 * @export
 * @interface DevicesApi
 */
export interface DevicesApiInterface {
    /**
     * 
     * @summary Get Device Detail List
     * @param {string} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApiInterface
     */
    getDeviceDetailListDevicesDetailGet(model?: string, options?: any): AxiosPromise<Array<DeviceDetail>>;

    /**
     * 
     * @summary Get Devices
     * @param {string} [model] 
     * @param {string} [deviceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApiInterface
     */
    getDevicesDevicesGet(model?: string, deviceId?: string, options?: any): AxiosPromise<Array<Device>>;

}

/**
 * DevicesApi - object-oriented interface
 * @export
 * @class DevicesApi
 * @extends {BaseAPI}
 */
export class DevicesApi extends BaseAPI implements DevicesApiInterface {
    /**
     * 
     * @summary Get Device Detail List
     * @param {string} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDeviceDetailListDevicesDetailGet(model?: string, options?: any) {
        return DevicesApiFp(this.configuration).getDeviceDetailListDevicesDetailGet(model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Devices
     * @param {string} [model] 
     * @param {string} [deviceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getDevicesDevicesGet(model?: string, deviceId?: string, options?: any) {
        return DevicesApiFp(this.configuration).getDevicesDevicesGet(model, deviceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParticipantsApi - axios parameter creator
 * @export
 */
export const ParticipantsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Participant
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParticipantParticipantsPost: async (participantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('createParticipantParticipantsPost', 'participantId', participantId)
            const localVarPath = `/participants/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (participantId !== undefined) {
                localVarQueryParameter['participant_id'] = participantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End All Open Assignments Of Participant Now
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endAllOpenAssignmentsOfParticipantNowParticipantsEndAllOpenNowPut: async (participantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('endAllOpenAssignmentsOfParticipantNowParticipantsEndAllOpenNowPut', 'participantId', participantId)
            const localVarPath = `/participants/end-all-open-now`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (participantId !== undefined) {
                localVarQueryParameter['participant_id'] = participantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End All Open Assignments Of Participant
         * @param {string} participantId 
         * @param {string} end Date and time when the assignment is ending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endAllOpenAssignmentsOfParticipantParticipantsEndAllOpenPut: async (participantId: string, end: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('endAllOpenAssignmentsOfParticipantParticipantsEndAllOpenPut', 'participantId', participantId)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('endAllOpenAssignmentsOfParticipantParticipantsEndAllOpenPut', 'end', end)
            const localVarPath = `/participants/end-all-open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (participantId !== undefined) {
                localVarQueryParameter['participant_id'] = participantId;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Participants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParticipantsParticipantsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/participants/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Participant With Id
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantWithIdParticipantsParticipantIdGet: async (participantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantId' is not null or undefined
            assertParamExists('getParticipantWithIdParticipantsParticipantIdGet', 'participantId', participantId)
            const localVarPath = `/participants/{participant_id}`
                .replace(`{${"participant_id"}}`, encodeURIComponent(String(participantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Participants Detail
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantsDetailParticipantsDetailGet: async (isActive?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/participants/detail/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParticipantsApi - functional programming interface
 * @export
 */
export const ParticipantsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParticipantsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Participant
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createParticipantParticipantsPost(participantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createParticipantParticipantsPost(participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary End All Open Assignments Of Participant Now
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endAllOpenAssignmentsOfParticipantNowParticipantsEndAllOpenNowPut(participantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endAllOpenAssignmentsOfParticipantNowParticipantsEndAllOpenNowPut(participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary End All Open Assignments Of Participant
         * @param {string} participantId 
         * @param {string} end Date and time when the assignment is ending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endAllOpenAssignmentsOfParticipantParticipantsEndAllOpenPut(participantId: string, end: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endAllOpenAssignmentsOfParticipantParticipantsEndAllOpenPut(participantId, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get All Participants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllParticipantsParticipantsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participants>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllParticipantsParticipantsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Participant With Id
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipantWithIdParticipantsParticipantIdGet(participantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Participant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticipantWithIdParticipantsParticipantIdGet(participantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Participants Detail
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipantsDetailParticipantsDetailGet(isActive?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParticipantDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticipantsDetailParticipantsDetailGet(isActive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParticipantsApi - factory interface
 * @export
 */
export const ParticipantsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParticipantsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Participant
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createParticipantParticipantsPost(participantId: string, options?: any): AxiosPromise<Participant> {
            return localVarFp.createParticipantParticipantsPost(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End All Open Assignments Of Participant Now
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endAllOpenAssignmentsOfParticipantNowParticipantsEndAllOpenNowPut(participantId: string, options?: any): AxiosPromise<any> {
            return localVarFp.endAllOpenAssignmentsOfParticipantNowParticipantsEndAllOpenNowPut(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End All Open Assignments Of Participant
         * @param {string} participantId 
         * @param {string} end Date and time when the assignment is ending.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endAllOpenAssignmentsOfParticipantParticipantsEndAllOpenPut(participantId: string, end: string, options?: any): AxiosPromise<any> {
            return localVarFp.endAllOpenAssignmentsOfParticipantParticipantsEndAllOpenPut(participantId, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Participants
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParticipantsParticipantsGet(options?: any): AxiosPromise<Participants> {
            return localVarFp.getAllParticipantsParticipantsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Participant With Id
         * @param {string} participantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantWithIdParticipantsParticipantIdGet(participantId: string, options?: any): AxiosPromise<Participant> {
            return localVarFp.getParticipantWithIdParticipantsParticipantIdGet(participantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Participants Detail
         * @param {boolean} [isActive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipantsDetailParticipantsDetailGet(isActive?: boolean, options?: any): AxiosPromise<Array<ParticipantDetail>> {
            return localVarFp.getParticipantsDetailParticipantsDetailGet(isActive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParticipantsApi - interface
 * @export
 * @interface ParticipantsApi
 */
export interface ParticipantsApiInterface {
    /**
     * 
     * @summary Create Participant
     * @param {string} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApiInterface
     */
    createParticipantParticipantsPost(participantId: string, options?: any): AxiosPromise<Participant>;

    /**
     * 
     * @summary End All Open Assignments Of Participant Now
     * @param {string} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApiInterface
     */
    endAllOpenAssignmentsOfParticipantNowParticipantsEndAllOpenNowPut(participantId: string, options?: any): AxiosPromise<any>;

    /**
     * 
     * @summary End All Open Assignments Of Participant
     * @param {string} participantId 
     * @param {string} end Date and time when the assignment is ending.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApiInterface
     */
    endAllOpenAssignmentsOfParticipantParticipantsEndAllOpenPut(participantId: string, end: string, options?: any): AxiosPromise<any>;

    /**
     * 
     * @summary Get All Participants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApiInterface
     */
    getAllParticipantsParticipantsGet(options?: any): AxiosPromise<Participants>;

    /**
     * 
     * @summary Get Participant With Id
     * @param {string} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApiInterface
     */
    getParticipantWithIdParticipantsParticipantIdGet(participantId: string, options?: any): AxiosPromise<Participant>;

    /**
     * 
     * @summary Get Participants Detail
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApiInterface
     */
    getParticipantsDetailParticipantsDetailGet(isActive?: boolean, options?: any): AxiosPromise<Array<ParticipantDetail>>;

}

/**
 * ParticipantsApi - object-oriented interface
 * @export
 * @class ParticipantsApi
 * @extends {BaseAPI}
 */
export class ParticipantsApi extends BaseAPI implements ParticipantsApiInterface {
    /**
     * 
     * @summary Create Participant
     * @param {string} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public createParticipantParticipantsPost(participantId: string, options?: any) {
        return ParticipantsApiFp(this.configuration).createParticipantParticipantsPost(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary End All Open Assignments Of Participant Now
     * @param {string} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public endAllOpenAssignmentsOfParticipantNowParticipantsEndAllOpenNowPut(participantId: string, options?: any) {
        return ParticipantsApiFp(this.configuration).endAllOpenAssignmentsOfParticipantNowParticipantsEndAllOpenNowPut(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary End All Open Assignments Of Participant
     * @param {string} participantId 
     * @param {string} end Date and time when the assignment is ending.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public endAllOpenAssignmentsOfParticipantParticipantsEndAllOpenPut(participantId: string, end: string, options?: any) {
        return ParticipantsApiFp(this.configuration).endAllOpenAssignmentsOfParticipantParticipantsEndAllOpenPut(participantId, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Participants
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public getAllParticipantsParticipantsGet(options?: any) {
        return ParticipantsApiFp(this.configuration).getAllParticipantsParticipantsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Participant With Id
     * @param {string} participantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public getParticipantWithIdParticipantsParticipantIdGet(participantId: string, options?: any) {
        return ParticipantsApiFp(this.configuration).getParticipantWithIdParticipantsParticipantIdGet(participantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Participants Detail
     * @param {boolean} [isActive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipantsApi
     */
    public getParticipantsDetailParticipantsDetailGet(isActive?: boolean, options?: any) {
        return ParticipantsApiFp(this.configuration).getParticipantsDetailParticipantsDetailGet(isActive, options).then((request) => request(this.axios, this.basePath));
    }
}


