import {
  AssignmentApi,
  Configuration,
  DevicesApi,
  ParticipantsApi,
} from '@/generated/api/study';
import { defineStore } from 'pinia';
import { Dictionary } from '@/util/interfaces';

export interface StudyApiState {
  basePath: string;
  baseOptions: {
    headers: Dictionary<string>;
  };
}

export const useStudyApi = defineStore({
  id: 'studyApi',
  state: (): StudyApiState => ({
    // will be set to "devicehub_study_app_base_path" from DMS client app setting "config"
    basePath: '',
    baseOptions: {
      headers: {},
    },
  }),

  getters: {
    configuration(): Configuration {
      return new Configuration({
        basePath: this.basePath,
        baseOptions: this.baseOptions,
      });
    },
    deviceApi(): DevicesApi {
      return new DevicesApi(this.configuration);
    },
    assignmentApi(): AssignmentApi {
      return new AssignmentApi(this.configuration);
    },
    participantApi(): ParticipantsApi {
      return new ParticipantsApi(this.configuration);
    },
  },

  actions: {
    setBasePath(basePath: string) {
      this.basePath = basePath;
    },

    setHeaders(headers: Dictionary<string>) {
      this.baseOptions.headers = headers;
    },
  },
});
